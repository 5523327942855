import React, {useCallback} from 'react';
import './Footer.scss';
import {L1, P2} from "../UI Kit/Text/Text";
import {scrollToSection} from "../Navbar/Navbar";

// const oldFooter = () => {
//     return (
//         <footer className="footer">
//             <p>
//                 &copy; {new Date().getFullYear()} Snow Smart - Tous droits réservés
//             </p>
//         </footer>
//     )
// }


const Footer = ({setActiveSection }) => {

    const handleScrollToSection = useCallback(
        (event, sectionId) => {
            scrollToSection(event, sectionId)
            setActiveSection(sectionId)
        },
        [setActiveSection]
    );


    return (
        <footer className="footer" id='section_contact'>

            <div className="footer-container">

                <div className="footer-col footer-col-1">

                    <p className={'snow-title'}>Snow</p>

                    <P2
                        text={`${new Date().getFullYear()} © Snow\nTous droits réservés`}
                        style={{marginTop: '-0.7em'}}
                        className={'footer_p2'}
                    />


                </div>


                <div className="footer-col footer-clickable footer-col-2 mobile_hidden">

                    <L1
                        text={'Accueil'}
                        onClick={(e) => handleScrollToSection(e, 'section_home')}
                    />

                    <L1
                        text={'Services'}
                        onClick={(e) => handleScrollToSection(e, 'section_services')}
                    />

                    <L1
                        text={'Contact'}
                        onClick={(e) => handleScrollToSection(e, 'section_contact')}
                    />


                </div>


                <div className="footer-col footer-col-3">

                    <L1
                        text={'LinkedIn'}
                        href={'https://www.linkedin.com/company/snow-smart'}
                        className={'mobile_hidden'}
                    />


                    <L1
                        text={'E-mail'}
                        href={'mailto:contact@snow-smart.fr'}
                        className={'mobile_hidden'}
                    />


                </div>


                <div className="footer-col footer-col-4">


                    <L1
                        text={'LinkedIn'}
                        href={'https://www.linkedin.com/company/snow-smart'}
                        className={'mobile_only'}
                    />

                    <L1
                        text={'E-mail'}
                        href={'mailto:contact@snow-smart.fr'}
                        className={'mobile_only'}
                    />

                    <L1
                        text={'Politique de confidentialité'}
                    />

                    <L1
                        text={'Conditions d\'utilisation'}
                    />


                </div>

            </div>

            <p>

            </p>
        </footer>
    )
}



export default Footer;
